.cart_container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.item_container{
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

@media only screen and (max-width:600px){
    .item_container{
        width: 100%;
    }
}
@media only screen and (max-width:800px){
    .item_container{
        width: 100%;
    }
}

@media only screen and (max-width:1000px){
    .item_container{
        width: 100%;
    }
}



