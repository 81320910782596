*{
    padding: 4px;
    margin-top: 10px;
}

.cont1{
    margin: 20px;
     padding: 0px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(230, 202, 202);
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows:90% 10%;
    width:300px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

   
}
.click_container{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 0.2fr 50%;
    width:300px;
}

.img-container{
    grid-row: 1/2;
    grid-column: 1;
    padding: 0;
}
img{
    width: 280px;
    height: 200px;
}

.title{
  padding: 0px;
  grid-row: 2/3;
  grid-column: 1;
}
.content{
    font-size: 12px;
    grid-row: 3/4;
    grid-column: 1;
}

@media only screen and (max-width:400px){
   
    .cont1{

        grid-template-columns: 100%;
        width: 80%;
        height: 5%;
        grid-template-rows: 1fr 0.2fr 50%fr;
    }
    img{
        width: 240px;
        height: 150px;
    }
    .content{
        font-size: 8px;
      
    }
}