

.search-input{
    border-radius: 10px;
    width: 20%;
    outline: none;
}
.your_item_cont{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.card_container{
  display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
  
}

@media only screen and (max-width:600px) {

    .search-input{
         border-radius: 10px;
         width: 100%;
         outline: none;
}
.card_container{
    width: 100%;
}
}